export const ConfirmMixin = {
  data: function () {
    return {
      confirmVisible: false
    };
  },
  computed: {
    displayedTitle() {
      return this.title || this.$tf('delete');
    },
    displayedHeader() {
      return this.header || this.$tf(`delete_confirm | ${this.target},,1`) + '?';
    }
  },
  methods: {
    open() {
      if (!this.hidden) {
        this.confirmVisiable = true;

        this.$confirm(this.displayedHeader, this.$tf('warning'), {
          confirmButtonText: this.$tf('ok'),
          cancelButtonText: this.$tf('cancel'),
          type: 'warning'
        })
          .then(() => {
            this.confirmVisiable = false;
            this.$emit('confirm');
          })
          .catch(() => {});
      }
    }
  }
};
