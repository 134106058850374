<template>
  <div class="threshold-selector">
    <el-checkbox v-model="thresholdEnabled">{{ $tfo('confidence_threshold') }}</el-checkbox>
    <template v-if="thresholdEnabled">
      <div v-for="[objects, singular] of multipleObjectsNames" class="threshold-selector__item">
        <div>{{ $tf(objects) }}:</div>
        <confidence-select-proxy :objects="objects" v-model="item[`${singular}_threshold`]" />
      </div>
    </template>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import ConfidenceSelectProxy from './confidence.select.proxy';

@Component({
  name: 'threshold-selector',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    ConfidenceSelectProxy
  }
})
export default class ThresholdSelector extends Component {
  get objectsNames() {
    return this.$store.getters.enabledObjects;
  }

  get objectNames() {
    return this.objectsNames.map((v) => `${ObjectsTypeSingleForm[v]}`);
  }

  get multipleObjectsNames() {
    return this.objectsNames.map((v) => [v, `${ObjectsTypeSingleForm[v]}`]);
  }

  get thresholdPropNames() {
    return this.objectNames.map((v) => `${v}_threshold`);
  }

  get thresholdEnabled() {
    return !!this.thresholdPropNames.find((v) => this.item[v] > 0);
  }

  set thresholdEnabled(value) {
    const { objectConfidenceThresholds } = this.$store.getters;
    this.objectNames.forEach((v) => {
      const propName = `${v}_threshold`,
        defaultThresholdPropName = `${v}_confidence_threshold`,
        defaultThreshold = 0.75,
        currentActiveThreshold = objectConfidenceThresholds[defaultThresholdPropName] || defaultThreshold;
      this.item[propName] = value ? currentActiveThreshold : 0;
    });
  }
}
</script>

<style lang="stylus">

.threshold-selector__item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
