<template>
  <confidence-select v-if="hasObjectsConfidence" :objects="objects" v-model="model" />
  <el-slider v-else v-model="model" :min="threshold.min" :max="threshold.max" :step="step" show-input />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import ConfidenceSelect from './confidence.select';
import { ObjectsType } from '@/store/objects/get.module';

const DefaultConfidenceStep = 0.01;

@Component({
  name: 'confidence-select-proxy',
  props: {
    objects: { type: String, default: ObjectsType.Faces },
    value: { type: Number, required: true }
  },
  components: { ConfidenceSelect }
})
export default class extends Vue {
  step = DefaultConfidenceStep;

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }

  get config() {
    return this.$store.state.config;
  }

  get threshold() {
    return this.config.event.threshold;
  }

  get hasObjectsConfidence() {
    return this.$store.getters.hasObjectsConfidence(this.objects);
  }
}
</script>
